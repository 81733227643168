$primary-color: #08152d;
$width: 100%;
$secondary-color: "gray";

// @import "src/variable.scss";

.underline-input {
  background-color: transparent;
  border: 0.5px solid #444444;
  border-width: 0px 0px 0.5px 0px;
  color: #08152d;
  border-color: #444444, 50%;
  width: 280px;
  border-radius: 0px;
  font-size: 18px;
  font-family: Montserrat;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

.underline-input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 15px;
}

.underline-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 15px;
}

.underline-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 15px;
}

.underline-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 15px;
}

.underline-input1 {
  background-color: transparent;
  border-width: 0px 0px 0px 0px;
  color: #166ab3;
  border-color: lightgray;
  width: 250px;
  padding: 10px;
  border-radius: 0px;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
.input-style {
  .ant-input {
    background: #f5f5f5;
    border-color: white;
    outline: 0;
    // -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    // box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    border: none;
    outline: none;

    &:focus {
      border-color: white;
      outline: 0;
      // -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
      // box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
      -webkit-box-shadow: 0 0 0 2px #f5f5f5;
      box-shadow: 0 0 0 2px #f5f5f5;
      border: none;
      outline: none;
    }
    &:hover {
      border-color: white !important;
      outline: 0;
      // -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
      // box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
      // -webkit-box-shadow: 0 0 0 2px white !important;
      // box-shadow: 0 0 0 2px white !important;
      border: none !important;
      outline: none !important;
    }
  }
}

.input-style1 {
  .ant-input {
    background: white;
    border-color: white;
    outline: 0;
    // -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    // box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
    border: none;
    outline: none;

    &:focus {
      border-color: white;
      outline: 0;
      // -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
      // box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
      -webkit-box-shadow: 0 0 0 1px white;
      box-shadow: 0 0 0 1px white;
      border: none;
      outline: none;
    }
    &:hover {
      border-color: white !important;
      outline: 0;
      // -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
      // box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
      -webkit-box-shadow: 0 0 0 2px white !important;
      box-shadow: 0 0 0 2px white !important;
      border: none !important;
      outline: none !important;
    }
  }
}

.light-shadow-right {
  //   -webkit-box-shadow: 2px 0px 5px 0px rgba(199, 191, 199, 1);
  //   -moz-box-shadow: 2px 0px 5px 0px rgba(199, 191, 199, 1);
  //   box-shadow: 2px 0px 5px 0px rgba(199, 191, 199, 1);
  -webkit-box-shadow: 10px 0px 5px -9px rgba(230, 230, 230, 1);
  -moz-box-shadow: 10px 0px 5px -9px rgba(230, 230, 230, 1);
  box-shadow: 10px 0px 5px -9px rgba(230, 230, 230, 1);
}
.light-shadow-left {
  -webkit-box-shadow: -1px 0px 5px 0px rgba(230, 230, 230, 1);
  -moz-box-shadow: -1px 0px 5px 0px rgba(230, 230, 230, 1);
  box-shadow: -1px 0px 5px 0px rgba(230, 230, 230, 1);
}
.light-shadow-top {
  -webkit-box-shadow: 0px -10px 5px -9px rgba(230, 230, 230, 1);
  -moz-box-shadow: 0px -10px 5px -9px rgba(230, 230, 230, 1);
  box-shadow: 0px -10px 5px -9px rgba(230, 230, 230, 1);
}
.light-shadow-bottom {
  -webkit-box-shadow: 0px -1px 5px 0px rgb(206, 194, 206);
  -moz-box-shadow: 0px -1px 5px 0px rgb(206, 194, 206);
  box-shadow: 0px -1px 5px 0px rgb(206, 194, 206);
  // box-shadow: 0 8px 6px -6px black;
  // color: rgba(0, 0, 0, 0.6);
  // box-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
  //   0px -5px 35px rgba(255, 255, 255, 0.3);
}

.new-shadow {
  // border-radius: 50px;
  // background: #ffffff;
  // box-shadow: 20px 20px 30px #d9d9d9, -20px -20px 60px #ffffff;
  border-radius: 0px;
  background: #ffffff;
  box-shadow: 14px 14px 28px #f2f2f2, 14px -14px 28px #ffffff;
}

.new-shadow1 {
  border-radius: 0px;
  background: #f5f5f5;
  box-shadow: 14px 14px 28px #e9e9e9;
}

.shadow-bottom-chat-1 {
  -webkit-box-shadow: 0px 6px 75px -31px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 6px 75px -31px rgba(0, 0, 0, 1);
  box-shadow: 0px 6px 75px -31px rgba(0, 0, 0, 1);
}
.shadow-bottom-chat-2 {
  -webkit-box-shadow: 1px 1px 5px 0px black;
  -moz-box-shadow: 1px 1px 5px 0px black;
  box-shadow: 1px 1px 5px 0px black;
}

.menu-itemss {
  padding: 0px 10px;
  margin: 0px 5px;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url(../fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato";
  font-weight: 900;
  src: local("Lato"), url(../fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato";
  font-weight: 900;
  src: local("Lato"), url(../fonts/Lato-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(../fonts/Montserrat-Regular.ttf) format("truetype");
}

.myFont {
  font-family: Lato;
}

.group-name {
  margin: 0px;
  padding: 5px 10px;
  margin: 2px 0px;
  color: #808080;
  cursor: pointer;
  &:hover {
    background-color: #333333;
    border-radius: 5px;
    color: #b5b5b5;
    cursor: pointer;
  }
}

.action-name {
  margin: 0px;
  padding: 5px 10px;
  margin: 2px 0px;
  color: lightgray;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
    border-radius: 5px;
    color: #162542;
  }
}

.selected-group {
  margin: 0px;
  padding: 5px 10px;
  // background-color: #333333;
  background-color: lightgray;
  border-radius: 5px;
  color: gray;
}
.selected-recipient {
  margin: 0px;
  // padding: 5px 10px;
  border-radius: 50px;
  // background-color: #333333;
  background-color: lightgray;

  color: gray;
}

.friend-name {
  color: #808080;
  padding: 5px 10px;
  margin: 2px 0px;
  cursor: pointer;
  &:hover {
    background-color: #333333;
    // background-color: lightgray;
    border-radius: 5px;
    color: #b5b5b5;
  }
}

.friend-name-dark {
  color: #808080;
  padding: 5px 10px;
  margin: 2px 0px;
  cursor: pointer;
  &:hover {
    background-color: #333333;
    border-radius: 5px;
    color: #b5b5b5;
  }
}

.selected-friend {
  margin: 0px;
  padding: 5px 10px;
  // background-color: #333333;
  background-color: darkgray;
  border-radius: 5px;
  // color: #b5b5b5;
  color: white;
}

.card-style {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 160px;
  margin: 10px;
}
.card-style1 {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100px;
  margin: 10px;
}

.chat-input {
  border: none;
  padding: 10px 0px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  // margin-bottom: 10px;
  padding-left: 20px;
  // max-height: 300px;
  word-wrap: break-word;
  display: inline-block;
  overflow-y: scroll;

  &:focus {
    border: none !important;
    outline: none;
  }
}
.selected-chat-input {
  border: none;

  padding: 10px 0px;
  width: 100%;
  background-color: #fff4df;
  border-radius: 8px;
  border-width: 4px;
  border-color: #e2c58e;
  // margin-bottom: 10px;
  padding-left: 20px;
  max-height: 300px;
  word-wrap: break-word;
  display: inline-block;

  &:focus {
    border: none !important;
    outline: none;
  }
}

.chat-input-dark {
  border: none;
  padding: 10px 0px;
  width: 100%;
  background-color: #2e2e2e;
  // background-color: #202020;
  color: white;
  border-radius: 8px;
  // margin-bottom: 10px;
  padding-left: 20px;
  max-height: 300px;
  word-wrap: break-word;
  display: inline-block;

  &:focus {
    border: none !important;
    outline: none;
  }
}

.chat-action-button {
  padding: 10px;
  display: none;
}

.selected-chat {
  padding: 0px 10px;
  // border-radius: 10px;
  background-color: #fff4df;
  cursor: pointer;
}

.selected-chat-dark {
  padding: 0px 10px;
  // border-radius: 10px;
  background-color: #2b2b2b;
  cursor: pointer;
}

.selected-chat1 {
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #fff4df;
  &:hover {
    cursor: pointer;
  }
}

.selected-delete-chat {
  padding: 0px 10px;
  // border-radius: 10px;
  background-color: rgb(248, 187, 208);
}
.selected-forward-chat {
  padding: 0px 10px;
  // border-radius: 10px;
  background-color: rgb(200, 230, 201);
}
.selected-all-chat {
  padding: 0px 10px;
  // border-radius: 10px;
  // background-color: rgb(200, 230, 201);
  background: #2196f3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f44336,
    #2196f3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f44336,
    #2196f3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.each-chat {
  padding: 0px 10px;
  border-radius: 10px;
  .hidden-timestamp {
    color: white;
  }
  &:hover {
    background-color: #eeeeee;
    // padding: "10px";
    .chat-action-button {
      // padding: 10px;
      display: block;
      cursor: pointer;
    }
    .hidden-timestamp {
      color: darkgrey;
      font-weight: bold;
    }
  }
}

.divider-dark {
  .ant-divider-horizontal.ant-divider-with-text-center::before,
  .ant-divider-horizontal.ant-divider-with-text-left::before,
  .ant-divider-horizontal.ant-divider-with-text-right::before,
  .ant-divider-horizontal.ant-divider-with-text-center::after,
  .ant-divider-horizontal.ant-divider-with-text-left::after,
  .ant-divider-horizontal.ant-divider-with-text-right::after {
    border-top: solid 1px gray !important;
  }
}
.divider-light {
  .ant-divider-horizontal.ant-divider-with-text-center::before,
  .ant-divider-horizontal.ant-divider-with-text-left::before,
  .ant-divider-horizontal.ant-divider-with-text-right::before,
  .ant-divider-horizontal.ant-divider-with-text-center::after,
  .ant-divider-horizontal.ant-divider-with-text-left::after,
  .ant-divider-horizontal.ant-divider-with-text-right::after,
  .ant-divider-horizontal.ant-divider-with-text-center::before,
  .ant-divider-horizontal.ant-divider-with-text-left::before,
  .ant-divider-horizontal.ant-divider-with-text-right::before,
  .ant-divider-horizontal.ant-divider-with-text-center::after,
  .ant-divider-horizontal.ant-divider-with-text-left::after,
  .ant-divider-horizontal.ant-divider-with-text-right::after {
    border-top: solid 1px lightgray;
  }
}

.each-chat-dark {
  padding: 0px 10px;
  border-radius: 10px;
  &:hover {
    background-color: #2e2e2e;
    // padding: "10px";
    .chat-action-button {
      // padding: 10px;
      display: block;
      cursor: pointer;
    }
  }
}

.show-menubar {
  display: none;
}
.dynamic-span-chatbox {
  column-span: 19;
}

div[data-placeholder]:not(:focus):not([data-div-placeholder-content]):before {
  content: attr(data-placeholder);

  float: left;

  margin-left: 2px;

  color: #b3b3b3;
}
.black-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: black;
}

.black-scroll::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.black-scroll::-webkit-scrollbar-thumb {
  background-color: #555555;
  border: 1px solid #555555;

  .scroll-management {
    width: 300px;
  }
}

.white-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  background-color: white;
}

.white-scroll::-webkit-scrollbar {
  // width: 1px;
  // background-color: #f5f5f5;
  width: 0px;
  background: transparent;
}

.white-scroll::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 100px;

  .scroll-management {
    width: 300px;
  }
}

.gray-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
  background-color: white;
}

.gray-scroll::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  // width: 0px;
  // background: transparent;
}

.gray-scroll::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 5px solid gray;
  border-radius: 200px;

  .scroll-management {
    width: 300px;
  }
}

.black-scroll1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #121212;
}

.black-scroll1::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.black-scroll1::-webkit-scrollbar-thumb {
  background-color: #555555;
  border: 1px solid #555555;

  .scroll-management {
    width: 300px;
  }
}

.black-scroll2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #202020;
}

.black-scroll2::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.black-scroll2::-webkit-scrollbar-thumb {
  background-color: #555555;
  border: 1px solid #555555;

  .scroll-management {
    width: 300px;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.chat-org-list {
  grid-column: span 1;
}
.chat-menu {
  grid-column: span 2;
}
.chat-box {
  grid-column: span 8;
}

.ant-tooltip-inner {
  color: black;
  background-color: white;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: green;
}

.html,
body {
  height: 100%;
}

.chatbox-container {
  min-height: 100%;
  position: relative;
}
.chatbox-header {
  background: #ff0;
  padding: 10px;
}
.chatbox-body {
  padding: 10px;
  padding-bottom: 60px;
}
.chatbox-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
  background: #6cf;
}
@media only screen and (max-width: 950px) {
  .hidechatmenu {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  // .hidden-sidebar {
  //   display: none !important;
  // }

  .show-menubar {
    display: block;
  }
  .dynamic-span-chatbox {
    column-span: 24;
  }
}

/* Typing Indicator Start*/

.ant-tabs-bar {
  margin: 0px;
}

.tiblock {
  align-items: center;
  display: flex;
  height: 17px;
}

.ticontainer .tidot {
  background-color: lightgray;
}

.tidot {
  -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 20px;
  display: inline-block;
  height: 7px;
  margin-right: 2px;
  margin-top: 5px;
  margin-bottom: 2px;
  width: 7px;
}

@keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
}

.tidot:nth-child(1) {
  -webkit-animation-delay: 200ms;
}
.tidot:nth-child(2) {
  -webkit-animation-delay: 300ms;
}
.tidot:nth-child(3) {
  -webkit-animation-delay: 400ms;
}

/* Typing Indicator End */

.todo-style {
  overflow: hidden;
  height: 65vh;
  &:hover {
    overflow-y: scroll;
  }
}

.profile-search {
  .ant-input {
    background-color: #26273a;
    color: white;
  }
}

.telegram-search {
  .ant-input {
    // background-color: #26273a;
    background-color: #2e2e2e;
    color: white;
    border: none;
    &:focus {
      border: solid 1px #00bcd4;
      outline: none;
      // outline-color: red;
    }
  }
}
.whatsapp-search {
  .ant-input {
    // background-color: #26273a;
    // background-color: #2e2e2e;
    // color: white;
    border: none;
    &:focus {
      border: solid 1px #00bcd4;
      outline: none;
      // outline-color: red;
    }
  }
}
.whatsapp-search-dark {
  .ant-input {
    background-color: #121212;
    color: white;
    border: none;
    &:focus {
      border: solid 1px #00bcd4;
      outline: none;
      // outline-color: red;
    }
  }
}
.total-amount {
  font-size: 40px;
  font-weight: bold;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.liquid-amount {
  font-size: 20px;
  font-weight: bold;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.pooled-amount {
  font-size: 20px;
  font-weight: bold;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.side-total {
  font-size: 20px;
  font-weight: bold;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}

.hover-background {
  &:hover {
    background-color: #f5f5f5;
  }
}

.input-border {
  margin: 5px 0px;
  padding: 5px;
  border: solid 1px #002a51;
  border-radius: 4px;
  width: 100%;
}

.payment-carousel {
  .control-arrow {
    // opacity: 0;
    background-color: transparent;
    background-size: 25px;
    width: 35px;
    &::before {
      content: none;
    }
    &.control-prev {
      border-right: none;
      background-image: url(../images/payment-methods/prev.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
    &.control-next {
      border-right: none;
      background-image: url(../images/payment-methods/next.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .slide {
    background: transparent !important;
    display: flex;
    opacity: 0.05;
    img {
      margin: 20px auto;
      width: 80%;
      height: 50px;
    }
    &.selected {
      opacity: 1;
    }
  }
}

.myeditor {
  position: fixed;
  bottom: -20;
  display: flex;
  flex-direction: column;
  width: inherit;
}
.myeditor-tab {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 75vw;
}

/* Telegram Theme UI */

.list-item {
  color: white;
  cursor: pointer;
  // &:hover {
  //   color: yellow;
  // }
}

.dark-tabs {
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    height: 40px;
    color: white;
    font-weight: bold;
    background: #121212;
    border-color: #2e2e2e;
    border: none;
    border-right: 1px solid #2e2e2e;

    .ant-tabs-close-x {
      color: white;
    }
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 40px;
    color: white;
    font-weight: bold;
    // background: #202020;
    background: #2e2e2e;
    // border-color: #e8e8e8;
    // border-bottom: 1px solid #fff;
    border: none;
    .ant-tabs-close-x {
      color: white;
    }
  }

  .ant-tabs-nav-scroll {
    background-color: #121212;
  }
}

// ChatBot
// padding: "10px 20px",
// border: "1px solid #E8EAEF",
// width: "150px",
// textAlign: "center",

.action-buttons {
  margin: 0px 5px;
  padding: 10px 20px;
  border: 1px solid #e8eaef;
  // width: 150px;
  text-align: center;
  font-weight: 600;
  color: #002a51;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    -webkit-box-shadow: 1px 1px 5px 0px rgb(206, 194, 206);
    -moz-box-shadow: 1px 1px 5px 0px rgb(206, 194, 206);
    box-shadow: 1px 1px 5px 0px rgb(206, 194, 206);
    cursor: pointer;
  }
}

.coin-buttons {
  margin: 0px 5px;
  padding: 10px 20px;
  border: 1px solid #e8eaef;
  // width: 150px;
  text-align: center;
  font-weight: 600;
  color: #002a51;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    -webkit-box-shadow: 1px 1px 5px 0px rgb(206, 194, 206);
    -moz-box-shadow: 1px 1px 5px 0px rgb(206, 194, 206);
    box-shadow: 1px 1px 5px 0px rgb(206, 194, 206);
    cursor: pointer;
  }
}

// MailKings CSS

.mail-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  // margin: 5px 0px;
  color: white;
  &:hover {
    cursor: pointer;
    background: #e8f0fa;
    color: #162542;
  }
}

.mail-menu-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  cursor: pointer;
  background: #e8f0fa;
  color: #162542;
}

.all-mail-list-item {
  cursor: pointer;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  &:hover {
    border-bottom: 1px solid #f5f5f5;
    border-top: 1px solid #f5f5f5;
  }
}
.create-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 10px;
  color: lightgray;
  border-bottom: 1px solid #f5f5f5;
  &:hover {
    color: #162542;
  }
}
.create-menu-selected {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px 10px;
  color: #162542;
  // color: lightgray;
  border-bottom: 1px solid #f5f5f5;
  border-top: 1px solid #f5f5f5;
}

.teamLogin {
  height: 100%;
  width: 100%;
  display: flex;
  .loginArea {
    flex: 0 0 45%;
    display: flex;
    .login-form {
      width: 45%;
      .group {
        position: relative;
        margin: 20% 0;
      }
      input[type="password"],
      input[type="text"] {
        width: $width;
        background: none;
        color: $secondary-color;
        font-size: 24px;
        padding: 10px 10px 10px 5px;
        display: block;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $secondary-color;
        &:focus {
          outline: none;
        }
        &:focus ~ label,
        &:valid ~ label {
          top: -14px;
          font-size: 12px;
          color: $secondary-color;
        }
        &:focus ~ .bar:before {
          width: $width;
        }
      }

      input[type="password"] {
        letter-spacing: 0.3em;
      }

      label {
        color: $secondary-color;
        font-size: 20px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 2px;
        top: 10px;
        // transition: $trans-time ease all;
      }
      .bt-solid {
        outline: none;
        width: 60%;
        height: 60px;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        color: white;
        font-size: 2.5vh;
        margin-bottom: 3vh;
        &:hover {
          background-color: #ee5440;
          color: white;
        }
      }
      .signUpBt {
        margin-top: 30px;
        font-size: 20px;
        span {
          cursor: pointer;
          font-weight: 600;
        }
      }
    }
  }
  .loginBg {
    width: 0;
    flex: 1;
    .loginImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.bg-img {
  background-image: url("../images/login_bg.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  object-fit: cover;
  // background-position: top center;
  // flex: 0 0 40%;
  // object-fit: contain;
  // background-size: cover;
  // background-position: 100%;
}
.font-montserrat {
  font-family: Montserrat;
}
.hover-shadow {
  &:hover {
    -webkit-box-shadow: -1px 9px 16px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 9px 16px -12px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 9px 16px -12px rgba(0, 0, 0, 0.75);
  }
}

.loginShadow {
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.shadow {
  -webkit-box-shadow: 0px 14px 37px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 14px 37px 0px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 14px 37px 0px rgba(0, 0, 0, 0.17);
}

.hover-underline {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.full-loading-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 99999999;
  .full-loading-logo {
    height: 65px;
    animation: leaves 0.75s infinite alternate;
  }
}

@keyframes leaves {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

.hoverstyle {
  transition: transform 0.5s ease;
  // padding-left: 7px;
  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
}
.reaction-modal {
  .ant-modal-body {
    padding: 0px;
    background-color: transparent;
    background: transparent;
  }
}
.reaction-style {
  padding: 0px 5px;
  border: solid 1px lightgray;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    border: solid 1px rgba(243, 93, 72, 0.7);
  }
}
.raise {
  &:hover {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
  }

  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
  }
}
.hoverstyle1 {
  transition: transform 0.5s ease;
  // padding-left: 7px;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
.popover-upload {
  .ant-popover-inner-content {
    padding: 0px;
  }
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 0px 0;
}

.img-responsive {
  max-height: calc(100vh - 225px);
  max-width: auto;
}

.img-responsive1 {
  max-height: 100vh;
  margin: -113px 0;
  padding: 113px 0;
}
.ant-tabs {
  overflow: unset;
}
.ant-popover-inner-content {
  padding: 0px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
  &:hover {
    background-color: rgba(128, 128, 128, 0.1);
    cursor: pointer;
  }
}
.ant-list-bordered {
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-color: #d9d9d9;
  // border: 1px solid ;
  border-radius: 2px;
}

.circle-border {
  display: block;
  // width: 2em;
  // height: 2em;
  border-radius: 100%;
  // background-color: #2ed091;
  margin: 5px;
  border: 2px solid lightgray;
  background-clip: content-box;
  padding: 3px;
}
.circle-border-admin {
  display: block;
  // width: 2em;
  // height: 2em;
  border-radius: 100%;
  // background-color: #2ed091;
  margin: 5px;
  border: 2px solid rgb(236, 64, 122);
  background-clip: content-box;
  padding: 3px;
}

.menu-border {
  // border-radius: 50px;
  // background: #ecf0f1;
  // background-color: red;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  // padding: 10px;
  // box-shadow: 5px 10px #888888;
  // box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  // background: linear-gradient(315deg, #e6e6e6, #ffffff);
  // box-shadow: -20px -20px 60px #d9d9d9, 20px 20px 60px #ffffff;
}
.neumorph {
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}
.neumorph-noshadow {
  border-radius: 50px;
  // background: #ffffff;
  background: transparent;
  color: white;
  .ant-input {
    background: transparent;
    color: white;
  }
}

.search-input-dark {
  border-radius: 50px;
  // background: #ffffff;
  background: transparent;
  color: white;
  .ant-input {
    background: transparent;
    color: white;
  }
}
.search-input-light {
  border-radius: 50px;
  // background: #ffffff;
  background: transparent;
  color: black;
  .ant-input {
    background: transparent;
    color: black;
  }
}

.neumorph-noround {
  // border-radius: 50px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.item {
  // border: 1px solid lightblue;
  margin: 5px;
  padding: 5px;
}

.item.active,
.item:hover {
  border-radius: 20px;
  background: gray;
  color: lightgray;
}

.item:hover {
  cursor: pointer;
}
// .editor-style {
//   .ant-mentions {
//     .textarea {
//       background: #1b1b1b !important;
//       color: white;
//     }
//   }
// }
// .textarea#focushere.rc-textarea {
//   background: #1b1b1b !important;
// }
// .textarea {
//   background: red;
// }
.ant-mentions > textarea {
  background: transparent !important;
}
.dark-mode-tabs {
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 2px 0 0;
    // background: transparent !important;
    background: #505050;
    color: lightgray;
    border-color: gray;
    border: none;
    margin-left: 0px;
    border-radius: 0px;
    &:hover {
      background: #2b2b2b;
    }
  }

  .ant-tabs-tab .ant-tabs-tab-remove .anticon {
    color: lightgray;
    &:hover {
      color: yellow;
      transform: scale(1.3);
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    color: lightgray;
    border: none;
  }
  .ant-tab-dropdown-open {
    background: red;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-radius: 2px 2px 0 0;
    // background: transparent !important;
    background: #1b1b1b;
    color: lightgray;
    border-color: gray;
    // border-bottom: none;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
  }
}
.light-mode-tabs {
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    // border-radius: 2px 2px 0 0;
    // background: transparent !important;
    background: lightgray;
    color: black;
    border: none;
    margin-left: 0px;
    border-radius: 0px;
    &:hover {
      background: #fafafa;
    }
  }

  .ant-tabs-tab .ant-tabs-tab-remove .anticon {
    color: black;
    &:hover {
      color: red;
      transform: scale(1.3);
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    color: black;
    border: none;
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-radius: 2px 2px 0 0;
    // background: transparent !important;
    background: transparent;
    color: lightgray;
    border-color: lightgray;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
  }
}

.action-dark {
  background: lightgray;
  color: black;
  height: 140px;
  font-weight: bold;
  &:hover {
    background: #f35d48;
    color: white;
  }
}
.action-light {
  background: gray;
  color: white;
  height: 140px;
  font-weight: bold;
  &:hover {
    background: #f35d48;
    color: white;
  }
}
.custom-ant-btn {
  .ant-btn-primary {
    background-color: var(--secondary-color);
    color: #448ef7;
    &:hover {
      background-color: #448ef7 !important;
      border-color: #448ef7 !important;
      color: white;
    }
  }
}
